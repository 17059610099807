import React from 'react';
import Board from './Board';

export class Game extends React.Component {

  renderBoard(j) {
	    return (
	    	<Board
          board={this.props.boards[j]}
          onClick={i => this.props.onClick(i, j)}
	      />
	    );
	  }

  render() {

    return (
      <div className="game" style={GameStyle}>
      	<div className="game-row" >
          {this.renderBoard(0)}
          {this.renderBoard(1)}
          {this.renderBoard(2)}
        </div>
        <div className="game-row" >
          {this.renderBoard(3)}
          {this.renderBoard(4)}
          {this.renderBoard(5)}
        </div>
        <div className="game-row" >
          {this.renderBoard(6)}
          {this.renderBoard(7)}
          {this.renderBoard(8)}
        </div>
      </div>
    );
  }
}

const GameStyle = {
	justifyContent: 'center',
}

export default Game;