import React from 'react'
import {BrowserRouter as Router, Link, Route, withRouter} from 'react-router-dom';
import App from '../App';
import fire from "../firebase"

const firestore = fire.firestore();

class NewGame extends React.Component {

	state = {
		  error: false,
		  errorMsg: '',
      player1: '',
      gameCode: '',
    };

	getP1 = (e) => this.setState({player1: e.target.value});
	getCode = (e) => this.setState({gameCode: e.target.value});

	onSubmit = (e) => {
		let mounted = true;
		e.preventDefault();
		console.log("code", this.state.gameCode);
		console.log("p1: ", this.state.player1);
		if(this.state.player1 === ''){
			console.log("code", this.state.gameCode);
		console.log("p1: ", this.state.player1);
			this.setState({player1: '', gameCode: '', error: true, errorMsg: 'Enter Player Name'});
			return;
		}
		if(this.state.gameCode === ''){
			this.setState({player1: '', gameCode: '', error: true, errorMsg: 'Enter Game Code'});
			return;
		}
		var code = this.state.gameCode;
		firestore.collection("games").doc(code)
      .get()
      .then(doc => {
        if(doc.exists && mounted) {
        	console.log("Document exists");
        	this.setState({player1: '', gameCode: '', error: true, 
        		errorMsg: 'Game code already taken, try another'});
        	return;
        } else {
          console.log("Invalid Document");
          this.props.newGame(this.state.player1, this.state.gameCode);
	  			this.redirectToHome();
        }
      }).catch(error => {
        console.log("Error getting document", error);
      });
	  return () => mounted = false;
	}

	redirectToHome = () => {
		const {history} = this.props;
		if(history) history.push('/');
	}

	render() {
		return (
			<Router>
			  <Route exact strict path="/" component={App}/> 
				<Route path="/NewGame" exact strict render={
            () => {
              return(
              <form onSubmit ={this.onSubmit} style={{marginTop: '20px'}}>
								<div className = "inputForm">
									<input 
										type="text" 
										name="Player1" 
										placeholder="Player Name: " 
										className="inputBox"
										value={this.state.player1}
										onChange={this.getP1}
									/>
									<input 
										type="text" 
										name="code" 
										placeholder="Unique Game Code: " 
										className="inputBox"
										value={this.state.gameCode}
										onChange={this.getCode}
									/>
								</div>
								{ this.state.error && <h5 className="error"> { this.state.errorMsg } </h5>}
								<div className = "inputForm">
									<button className="startBtn" style={{margin: '0px 20px', width: '120px'}} onClick ={this.onSubmit}>Start New Game</button>
									<button className="startBtn" style={{margin: '0px 20px', width: '120px'}} onClick ={this.redirectToHome}>Back</button>
								</div>
						</form>);}}/>
			</Router>
		);
	}
}

export default withRouter(NewGame);