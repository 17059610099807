import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyDsOFW838cpmXIYTajm_vg3Z9TfWH0G5-A",
  authDomain: "tictactoe-fc437.firebaseapp.com",
  databaseURL: "https://tictactoe-fc437.firebaseio.com",
  projectId: "tictactoe-fc437",
  storageBucket: "tictactoe-fc437.appspot.com",
  messagingSenderId: "938387483631",
  appId: "1:938387483631:web:52489fe9b78d95af82641a",
  measurementId: "G-6XD9ELKV1M"
};
const fire = firebase.initializeApp(config);

export default fire;