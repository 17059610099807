import React from 'react'
import {BrowserRouter as Router, Link, Route, withRouter} from 'react-router-dom';
import App from '../App'
import fire from "../firebase"

const firestore = fire.firestore();

class JoinGame extends React.Component {

	state = {
			error: '',
      player2: '',
      enteredCode: '',
      wrongCode: false,
      empty: false,
  };

	getP2 = (e) => this.setState({player2: e.target.value});
	getCode = (e) => this.setState({enteredCode: e.target.value});

	onSubmit = (e) => {
		e.preventDefault();
		var player2 = this.state.player2;
		var code = this.state.enteredCode;
		if(this.state.player2 === ''){
			this.setState({enteredCode:'', empty: true, error: 'Enter Player Name'});
			return;
		}
		firestore.collection("games").doc(this.state.enteredCode)
			.get()
			.then(doc => {
				if(doc.exists) {
					var item = doc.data();
					if(item.gameInfo.winner === false){
						item.players.player2 = player2;
						item.players.added = true;
						console.log(item.players);
						firestore.collection("games").doc(doc.id).update({players: item.players});
						this.props.joinGame(code);
						this.redirectToHome();
					}
				} else {
					console.log("Invalid Document");
					this.setState({wrongCode: true, error: 'Wrong Code'});
				}
			}).catch(error => {
				console.log("Error getting document", error);
			});
		this.setState({player2: ''});
	  this.setState({enteredCode: ''});
	}

	updateDB() {
    firestore.collection("games").doc(this.props.ID).update({
      players: this.props.players});
  }

	redirectToHome = () => {
		const {history} = this.props;
		if(history) history.push('/');
	}

	updateDB() {
		var players = this.props.players;
		players.player2 = this.state.player2;
    firestore.collection("games").doc(this.props.code).update({
      players: players,
    });
  }

  errorStyle = () => {
    return {
      value: this.state.error,
      display: (this.state.wrongCode || this.state.empty) ? 'block' : 'none',
      color: 'white',
    }
  }

 render() {
		return (
			<Router>
			  
				<Route path="/JoinGame" exact strict render={
            () => {
              return(
              <form onSubmit ={this.onSubmit} style={{marginTop: '20px'}}>
								<div className = "inputForm">
									<input 
										type="text" 
										name="Player2" 
										placeholder="Player Name: " 
										className="inputBox"
										value={this.state.player2}
										onChange={this.getP2}
									/>
									<input 
										type="text" 
										name="code" 
										placeholder="Unique Game Code: "
										className="inputBox"
										value={this.state.enteredCode}
										onChange={this.getCode}
									/>
								</div>
								<div style={this.errorStyle()}></div>
								{ (this.state.empty || this.state.wrongCode) && <h5 className="error"> { this.state.error } </h5> }
								<div className = "inputForm">
									<button className="startBtn" style={{margin: '0px 20px', width: '120px'}} onClick ={this.onSubmit}>Join Game</button>
									<button className="startBtn" style={{margin: '0px 20px', width: '120px'}} onClick ={this.redirectToHome}>Back</button>
								</div>
						</form>);}}/>
			</Router>
		);
	}
}
	

export default withRouter(JoinGame);