import React from 'react';
import Square from './Square';

export class Board extends React.Component {

	renderSquare(i) {
	    return (
	      <Square
	        value={this.props.board.squares[i]}
	        onClick={() => this.props.onClick(i)}
	      />
	    );
	}

	boardStyle = () => {
		return {
			textAlign: 'center',
			justifyContent: 'left',
			margin: '5px',
			padding: '5px',
			// background: '#46C7C7'
			background: this.props.board.completed ? 
			  (this.props.board.p1_p2 ? '#CC0000' : '#0000CC' ) 
			: (this.props.board.current ? '#46C7C7' : '#282c34')
		}
	}

  render() {
    return (
      <div className="Board">
	      <center><div style={this.boardStyle()}>
		      <div className="board-row">
	          {this.renderSquare(0)}
	          {this.renderSquare(1)}
	          {this.renderSquare(2)}
	        </div>
	        <div className="board-row">
	          {this.renderSquare(3)}
	          {this.renderSquare(4)}
	          {this.renderSquare(5)}
	        </div>
	        <div className="board-row">
	          {this.renderSquare(6)}
	          {this.renderSquare(7)}
	          {this.renderSquare(8)}
	        </div>
	      </div></center>
      </div>
    );
  }
}

export default Board;