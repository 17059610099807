import React, {Component} from 'react';
import './App.css';
import UltimateGame from './Components/UltimateGame';
//import fire from './firebase';
import GetPlayers from './Components/GetPlayers';
import NewGame from './Components/NewGame';
import JoinGame from './Components/JoinGame';
import {BrowserRouter as Router, NavLink, Route} from 'react-router-dom';
import fire from "./firebase"

const firestore = fire.firestore();

class App extends Component {
  intervalID;

  newBoard() {
    return({
      squares: Array(9).fill(null),
      completed: false,
      numItems: 0,
      p1_p2: 0,
      current: false,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      ID: null,
      showInfo: false,
      change: false,
      showButtons: true,
      singleScreen: false,
      currentP1: false,
      boards: [this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard()],
      gameInfo: {
        boardNum: 4,
        nextBoard: 10,
        squareNum: 0,
        firstStep: true,
        xIsNext: true,
        winner: false,
        ended: false,
      },
      players: {
        player1: '',
        player2: '',
        added: false,
      },
    };
  }

  componentWillMount(){
    if(this.state.showInfo){
      console.log("showing info");
      this.updateState();
    }
  }

  componentWillUnmount(){
    clearTimeout(this.intervalID);
  }

  // componentWillMount() {
  //   if(this.state.ID && this.state.change){
  //     this.updateState();
  //   }
  //   console.log("change:", this.state.change);
  //   this.state.change = false;
  // }

  rulesStyle = () => {
    return {
      value: 'Learn to Play',
      background: '#8EC8D0',
      color: 'black',
      padding: '5px',
      border: '10px solid black',
      position: 'absolute',
      justify: 'right',
      align: 'center',
      display: 'in-line-block',
      float: 'right',
    }
  }

  addToDB() {
    firestore.collection("games").add({
      players: this.state.players,
      gameInfo: this.state.gameInfo,
      boards: this.state.boards,
    })
      .then(docRef => {
        console.log(docRef.id);
        this.state.ID = docRef.id;
      })
      .catch(error => console.log("error adding document: ", error));
  }

  newDB(gameCode) {
    firestore.collection("games").doc(gameCode).set({
      players: this.state.players,
      gameInfo: this.state.gameInfo,
      boards: this.state.boards,
    });
  }

  updateState() {
    firestore.collection("games").doc(this.state.ID)
      .get()
      .then(doc => {
        if(doc.exists) {
          var item = doc.data();
          this.setState({
            players: item.players,
            boards: item.boards,
            gameInfo: item.gameInfo,
          });
          this.intervalID = setTimeout(this.updateState.bind(this), 1000);
        } else {
          console.log("Invalid Document");
          this.setState({wrongCode: true, error: 'Wrong Code'});
        }
      }).catch(error => {
        console.log("Error getting document", error);
    });
  }

  restartGame() {
    window.location.reload(true);
    // console.log("restarting");
    // firestore.collection("games").doc("empty")
    //   .get()
    //   .then(doc => {
    //     if(doc.exists) {
    //       var item = doc.data();
    //       this.setState({
    //         players: item.players,
    //         boards: item.boards,
    //         gameInfo: item.gameInfo,
    //         ID: null,
    //         showInfo: false,
    //         change: false,
    //         showButtons: true,
    //         singleScreen: false,
    //         currentP1: false,
    //       });
    //       clearTimeout(this.intervalID);
    //       console.log(this.state.players);
    //       console.log(this.state.gameInfo);
    //       console.log(this.state.boards);
    //       console.log("show btns", this.state.showButtons);
    //       this.render();
    //     } else {
    //       console.log("Invalid Document");
    //     }
    //   }).catch(error => {
    //     console.log("Error getting document", error);
    // });
  }

  updateDB() {
    firestore.collection("games").doc(this.state.ID).update({
      players: this.state.players,
      gameInfo: this.state.gameInfo,
      boards: this.state.boards});
  }

   addPlayers(player1, player2){
    if(player1 === '' || player2 === ''){
      return;
    }
    const players = {...this.state.players};
    players.added = true;
    players.player1 = player1;
    players.player2 = player2;
    this.setState({
      players: players,
      singleScreen: true,
    });
    //this.addToDB();
  }

  hideButtons = () => {
    this.state.showButtons = false;
  }

  newGame(player1, gameCode){
    const players = {...this.state.players};
    players.player1 = player1;
    this.setState({
      players: players,
      ID: gameCode,
    });
    this.newDB(gameCode);
    this.state.currentP1 = true;
    this.state.showInfo = true;
    this.hideButtons();
    this.intervalID = setTimeout(this.updateState.bind(this), 1000);
  }

  playerStyle = () => {
    return {
      display: this.state.singleScreen ? 'block' : 'none', 
    }
  }

  btnStyle = (num) => {
    return {
      display: num ? ((this.state.showButtons) ? 'in-line-block' : 'none') : 'block',
      width: '170px',
      background: '#fff',
      fontSize: '16px',
      textAlign: 'center',
      color: 'black',
      textDecoration: 'none',
      border: 'solid',
      fontFamily: 'Arial',
      borderColor: '#46C7C7',
      borderWidth: '3px',
      padding: '7px 7px',
      margin: num ? '20px 20px' : '0px 0px 50px 0px',
    }
  }

  playAgain() {
    console.log('playing again');
    this.state = {
      boards: [this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard(), this.newBoard()],
      gameInfo: {
        boardNum: 4,
        nextBoard: 10,
        squareNum: 0,
        firstStep: true,
        xIsNext: true,
        winner: false,
        ended: false,
      },
      players: {
        player1: '',
        player2: '',
        added: false,
      },
    };
    console.log("winner:", this.state.gameInfo.winner);
    console.log("player1", this.state.players.player1);
    console.log("board 1: ", this.state.boards[2].numItems);
    this.setState({singleScreen: false, showButtons: true,});
    this.updateDB();
  }

  updatePlayers = (code) => {
    var players;
    firestore.collection("games").doc(code)
      .get()
      .then(doc => {
        if(doc.exists) {
          var item = doc.data();
          players = item.players;
          players.added = true;
          console.log("players here", players);
          this.setState({players: players});
          console.log("here", players);
        } else {
          console.log("Invalid Document");
        }
      }).catch(error => {
        console.log("Error getting document", error);
      });
      //this.state.players = players;
      //console.log(this.state.players);
  }

  logEverything() {
    console.log("players: ", this.state.players);
    console.log("boards: ", this.state.boards);
    console.log("gameInfo: ", this.state.gameInfo);
  }

  joinGame = (code) => {
    this.state.ID = code;
    this.hideButtons();
    this.updatePlayers(code);
    this.state.change = true;
    console.log(this.state.players);
    this.state.currentP1 = false;
    this.state.showInfo = true;
    this.intervalID = setTimeout(this.updateState.bind(this), 1000);
  }

  singleScreen() {
    this.setState({singleScreen: true, showButtons: false,});
  }

  foundWinner = () => {
    this.state.gameInfo.winner = true;
    this.setState({
      val: 2,
    })
  }

  justClicked(boards) {
    this.setState({
      boards: boards,
    });
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route path='/' exact strict render={
            () => {
              return(
                <div>
                <div style={{width: '100%', height:'20px'}} ><a className="rules" href="https://www.thegamegal.com/2018/09/01/ultimate-tic-tac-toe/">Learn to Play</a></div>
                <UltimateGame 
                  winner={this.state.gameInfo.winner}
                  updateState={() => this.updateState()}
                  showInfo= {this.state.showInfo}
                  ID= {this.state.ID}
                  change = {this.state.change}
                  singleScreen = {this.state.singleScreen}
                  currP1={this.state.currentP1}
                  boards={this.state.boards}
                  gameInfo={this.state.gameInfo}
                  justClicked={(boards) => this.justClicked(boards)}
                  players={this.state.players}
                  playAgain={() => this.playAgain()}
                  restartGame={() => this.restartGame()}
                  foundWinner = {this.foundWinner}/>
                <div className = "buttons" 
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',}}>
                  <button style={this.btnStyle(true)} onClick={this.singleScreen.bind(this)}>Single-Screen Game</button>
                  <NavLink className="newgame" to="/NewGame" exact strict style={this.btnStyle(true)} >Start New Game</NavLink>
                </div>
                <div className = "button"style={{justifyContent: 'center',}}>
                  <NavLink to="/JoinGame" exact strict style={this.btnStyle(true)} >Join Existing Game</NavLink>
                  <GetPlayers players={this.state.players} addPlayers={(p1, p2) => this.addPlayers(p1, p2)} show={this.state.singleScreen && !this.state.players.added}/>
                </div>
                </div>
                );}}>
          </Route>
          <Route path="/NewGame" exact strict render={
            () => {return(
              <NewGame players={this.state.players} newGame={(p1, code) => this.newGame(p1, code)}/>);
            }}/>
          <Route path="/JoinGame" exact strict render={
            () => { return(
              <JoinGame 
                joinGame = {(code) => this.joinGame(code)}
                players={this.state.players} 
                code={this.state.ID}
                newGame={(p1, code) => this.newGame(p1, code)}/> );}}/>
        </Router>
      </div>
    );
  }
}

export default App;