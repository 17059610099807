import React from 'react'

export class GetPlayers extends React.Component {
	playerStyle = () => {
		return{
			display: this.props.show ? 'block' : 'none',
			justifyContent: 'center',
		}
	}

	state = {
      title: '',
      player1: '',
      player2: '',
    };

	getP1 = (e) => this.setState({player1: e.target.value});
	getP2 = (e) => this.setState({player2: e.target.value});

	onSubmit = (e) => {
		e.preventDefault();
		this.props.addPlayers(this.state.player1, this.state.player2);
		this.setState({player1: ''});
	  this.setState({player2: ''});
	}

	render() {
		return (
			<form onSubmit ={this.onSubmit} style = {this.playerStyle()}>
				<div className = "inputForm">
					<input 
						type="text" 
						name="Player1" 
						placeholder="Player 1: " 
						className="inputBox"
						value={this.state.player1}
						onChange={this.getP1}
					/>
					<input 
						type="text" 
						name="Player2" 
						placeholder="Player 2: " 
						className="inputBox"
						value={this.state.player2}
						onChange={this.getP2}
					/>
				</div>
				<div className = "inputForm">
				<input
					type="submit"
					value="Start Game"
					className="startBtn"	
				/>
				</div>
			</form>
		);
	}
}

export default GetPlayers;