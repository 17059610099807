import React from 'react';
import Game from './Game';
import fire from "../firebase"

const firestore = fire.firestore();

export class UltimateGame extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ended: false,
      wait: false,
      show: false,
    };
  }
  
  statusStyle = () => {
    return {
      display: this.props.players.added ? 'block' : 'none', 
      marginBottom: '10px',
      fontSize: this.props.gameInfo.winner ? '36px' : '24px',
      textDecoration: this.props.gameInfo.winner ? 'underline' : 'none',
      // color: this.state.winner ? 
      //   (this.state.xIsNext ? '#CC0000' : '#0000CC' ) : 'white',
    }
  }

  playersStyle = () => {
    return {
      display: this.props.players.added ? 'none' : 'block',
    }
  }

  handleClick(i, j) {
    console.log("single screen", this.props.singleScreen);
    if(this.props.singleScreen){
      this.handleClickNoDB(i, j);
    }
    else{
      this.handleClickDB(i, j);
    }
  }

  handleClickDB(i, j) {
    if(this.props.ID === null){
      console.log("is null");
      return;
    }
    firestore.collection("games").doc(this.props.ID)
      .get()
      .then(doc => {
        if(doc.exists) {
          var item = doc.data();
          var players = item.players;
          var gameInfo = item.gameInfo;
          var boards = item.boards;
          console.log(players);
          console.log(gameInfo);
          console.log(boards);
          this.performActions(players, gameInfo, boards, i, j);
        } else {
          console.log("Invalid Document");
        }
      }).catch(error => {
        console.log("Error getting document", error);
      });
  }

  performActions(players, gameInfo, boards, i, j){
    var currBoard = boards[j];
    console.log(i, j);
    console.log("added", players.added);
    console.log("currP1", this.props.currP1);
    console.log("xNext:", gameInfo.xIsNext);
    if(this.props.currP1){
      this.setState({show: true});
    }
    if((players.added === false) ||  ((this.props.currP1 && !(gameInfo.xIsNext)) 
          || (!this.props.currP1 && gameInfo.xIsNext))){
      this.setState({wait: true});
      console.log("waiting");
      return;
    }
    else{
      this.setState({wait: false});
    }
    if(!currBoard.completed && gameInfo.firstStep === true){
      gameInfo.firstStep = false;
    }
    else if(gameInfo.nextBoard !== j){
      return;
    }
    if (ultimateWinner(boards) || currBoard.numItems === 9 || 
      currBoard.squares[i] !== null || currBoard.completed) {
      return;
    }
    currBoard.squares[i] = gameInfo.xIsNext ? 'X' : 'O';
    currBoard.numItems = currBoard.numItems + 1;
    boards[gameInfo.boardNum].current = false;
    boards[j].current = false;
    boards[j].p1_p2 = gameInfo.xIsNext ? 0 : 1;
    const winner = calculateWinner(currBoard.squares);
    if(winner){
      currBoard.completed = true;
    }
    if(boards[i].completed === true){
      gameInfo.firstStep = true;
    }
    else if (!ultimateWinner(boards)){
      boards[i].current = true;
    }
    gameInfo.nextBoard = i;
    gameInfo.squareNum = i;
    gameInfo.xIsNext = !gameInfo.xIsNext;
    if(ultimateWinner(boards)){
      this.setState({ended: true});
      gameInfo.winner = true;
    }
    //this.props.change = true;
    this.updateDB(gameInfo, boards);
    this.props.updateState();
  }

  handleClickNoDB(i, j) {
    const boards = this.props.boards;
    const currBoard = this.props.boards[j];
    console.log(i, j);
    console.log("added", this.props.players.added);
    console.log("currP1", this.props.currP1);
    if(this.props.players.added === false){
      return;
    }
    if(!currBoard.completed && this.props.gameInfo.firstStep === true){
      this.props.gameInfo.firstStep = false;
    }
    else if(this.props.gameInfo.nextBoard !== j){
      return;
    }
    if (ultimateWinner(boards) || currBoard.numItems === 9 || 
      currBoard.squares[i] !== null || currBoard.completed) {
      return;
    }
    currBoard.squares[i] = this.props.gameInfo.xIsNext ? 'X' : 'O';
    currBoard.numItems = currBoard.numItems + 1;
    boards[this.props.gameInfo.boardNum].current = false;
    boards[j].current = false;
    boards[j].p1_p2 = this.props.gameInfo.xIsNext ? 0 : 1;
    const winner = calculateWinner(currBoard.squares);
    if(winner){
      currBoard.completed = true;
    }
    if(boards[i].completed === true){
      this.props.gameInfo.firstStep = true;
    }
    else if (!ultimateWinner(boards)){
      boards[i].current = true;
    }
    this.props.gameInfo.nextBoard = i;
    this.props.gameInfo.squareNum = i;
    this.props.gameInfo.xIsNext = !this.props.gameInfo.xIsNext;
    this.props.justClicked(boards);
    if(ultimateWinner(boards)){
      this.setState({ended: true});
      this.props.gameInfo.winner = true;
    }
  }

  updateDB(gameInfo, boards) {
    firestore.collection("games").doc(this.props.ID).update({
      gameInfo: gameInfo,
      boards: boards});
  }

  againStyle = () => {
    return {
      display: (this.props.winner) ? 'inline' : 'none',
      width: '100px',
      background: '#fff',
      fontSize: '16px',
      textAlign: 'center',
      color: 'black',
      textDecoration: 'none',
      border: 'solid',
      borderColor: '#46C7C7',
      borderWidth: '3px',
      padding: '5px',
      margin: '10px',
    }
  }

  render() {
    const boards = this.props.boards.slice();
    const winner = ultimateWinner(boards);
    let status;
    if (winner) {
      status = "Winner: " + (this.props.gameInfo.xIsNext ? this.props.players.player2 
        : this.props.players.player1);
    } else {
      status = "Next player:  " + (this.props.gameInfo.xIsNext ? this.props.players.player1 
        : this.props.players.player2) + " (" + (this.props.gameInfo.xIsNext ? "X" : "O") + ")";
    }

    return (
      <div className="game">
        <h1>Tic Tac Toe</h1>
        <div className="status" style={this.statusStyle()}>
          <div>{status}</div>
        </div>
        <div className="game-board">
          <Game
            boards={boards}
            onClick={(i, j) => this.handleClick(i, j)}
          />
        </div>
        <div style={{justifyContent: 'center', }}>
          <button style={this.againStyle()} onClick={() => this.props.restartGame()}>Play Again</button>
        </div>
        {this.props.showInfo &&
          <h5 style={{margin: '10px'}}>Player {this.props.currP1 ? this.props.players.player1 : this.props.players.player2 }
          <br/> Game Code: {this.props.ID}</h5>}
        {this.state.wait && <h5 style={{margin: '10px'}}>Waiting on Other Player</h5>}
      </div>
    );
  }
}

export default UltimateGame;

// const User = (params) => {
//   return(<h1>I'm a User {params.username}</h1>)
// }

function ultimateWinner(boards) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if ((boards[a].completed && boards[a].p1_p2 === 0) &&
        (boards[b].completed && boards[b].p1_p2 === 0) &&
        (boards[c].completed && boards[c].p1_p2 === 0)) {
      return true;
    }
    if ((boards[a].completed && boards[a].p1_p2 === 1) &&
        (boards[b].completed && boards[b].p1_p2 === 1) &&
        (boards[c].completed && boards[c].p1_p2 === 1)) {
      return true;
    }
  }
  return false;
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}

//<div className = "getPlayers" style={this.playersStyle()}>
//  <GetPlayers 
//    players = {this.state.players}
//    addPlayers = {(p1, p2) => this.addPlayers(p1, p2)}
//    render = {this.render} />
//</div>

// <input type="button" value={this.state.loggedIn ? 'Log Out' : 'Log In'} onClick={this.login.bind(this)}/>
//         <Router> 
//           <ul>
//           <li>
//             <NavLink to="/" exact strict style={{color: 'white'}} activeStyle={{color:'green'}}>Home</NavLink>
//           </li>
//           <li> 
//             <NavLink to="/about" exact strict style={{color: 'white'}} activeStyle={{color:'green'}}>About</NavLink>
//           </li>
//           <li>
//             <NavLink to="/user/john" exact strict style={{color: 'white'}} activeStyle={{color:'green'}}>User John</NavLink>
//           </li>
//           </ul>
//           <Prompt 
//             when={!this.state.loggedIn}
//             message={(location) => {
//               return location.pathname.startsWith('/user') ? 'Are you sure?' : true}}/>

//           <Route path="/" exact strict render={
//             () => {
//               return(<h1> Welcome Home</h1>);
//             }
//           }>
//           </Route>
//           <Route path="/about" exact strict render={
//             () => {
//               return(<h1>About</h1>);
//             }
//           }>
//           </Route>
//           <Route path="/user/:username" exact strict render={({match})=>(
//             this.state.loggedIn ? (<User username={match.params.username}/>) : (<Redirect to="/"/>)  
//           )}>
//           </Route>
//         </Router>